import { get } from "lodash";
export default {
  code: "offDisabled",
  name: "关闭禁用",
  desc: "关闭禁用",
  func: function(paths) {
    paths.forEach(path => {
      const config = get(this.pageLayoutMap, path, null);
      if (!config) {
        return false;
      }
      config.disabled = false;
    });
  }
};
