import { set } from "lodash";
import dayjs from "dayjs";
export default {
  code: "setReturnDate",
  name: "还车时间操作",
  desc: "设置还车时间",
  func: function() {
    if (!this.val) {
      return false;
    }
    set(
      this.pageForm,
      "walk.returnDate",
      dayjs(this.val)
        .add("3", "day")
        .toDate()
    );
    set(
      this.pageForm,
      "walk.returnDate",
      new Date(
        dayjs(this.val)
          .add("3", "day")
          .toDate()
      ).getTime()
    );
  }
};
