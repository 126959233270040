import dayjs from "dayjs";
import common from "@/utils/common";
export default {
  code: "idCard",
  name: "身份证处理",
  desc: "身份证处理",
  func: function() {
    if (!this.val) {
      return false;
    }
    const certificateType = this.form.certificateType;
    if (certificateType !== "1") {
      return false;
    }
    if (!/(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/.test(this.val)) {
      return false;
    }
    const birthDay = dayjs(common.getBirthDay(this.val)).valueOf();
    const gender = common.getGender(this.val);
    this.form.birthday = birthDay;
    this.form.gender = gender;
  }
};
