export default {
  code: "certificateTypeChange",
  name: "客户证件类型修改",
  desc: "客户证件类型修改",
  func: function() {
    if (!this.val) {
      return false;
    }
    if (this.val === "6") {
      // 公司
      this.setVal("customer.customerType", "2");
      this.offShow(["customer.birthday", "customer.gender"]);
    } else {
      // 个人
      this.setVal("customer.customerType", "1");
      this.onShow(["customer.birthday", "customer.gender"]);
      if (this.val === "1") {
        // 身份证
        this.onReadonly(["customer.birthday", "customer.gender"]);
        return false;
      }
      this.offReadonly(["customer.birthday", "customer.gender"]);
    }
  }
};
