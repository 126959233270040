import dayjs from "dayjs";
export default {
  code: "calcApplyDays",
  name: "计算天数",
  desc: "计算天数",
  func: function(format) {
    if (!(this.pageForm.fault.repairStartDate && this.pageForm.fault.repairEndDate)) {
      return false;
    }
    const applyDays = dayjs(this.pageForm.fault.repairEndDate).diff(dayjs(this.pageForm.fault.repairStartDate), format || "day");
    this.pageForm.walk.applyDays = applyDays < 5 ? applyDays : 5;
  }
};
