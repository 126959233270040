import { request } from "@/api/service";
export default {
  code: "contractToClaim",
  name: "合同转换履约信息",
  desc: "合同转换履约信息",
  func: async function() {
    const { id, contractId, coverageId } = this.pageContext.$route.query;
    this.pageForm.id = id;
    if (contractId) {
      this.usableBenefits(contractId);
    }
    if (id || !contractId) {
      return false;
    }
    this.pageForm.contractId = contractId;
    this.pageForm.coverageId = coverageId;
    const res = await request({
      url: `/afis-carservice/contract/info/${contractId}`,
      method: "get"
    });
    const { customer, vehicle } = res;
    if (customer) {
      this.pageForm.customer.reportName = customer.customerName;
      this.pageForm.customer.reportPhone = customer.phone;
      this.pageForm.account.accountName = customer.customerName;
    }
    if (vehicle) {
      this.pageForm.vehicle.plateNo = vehicle.plateNo;
    }
  }
};
