import { get } from "lodash";
export default {
  code: "onReadonly",
  name: "打开禁用",
  desc: "打开禁用",
  func: function(paths) {
    paths.forEach(path => {
      const config = get(this.pageLayoutMap, path, null);
      if (!config) {
        return false;
      }
      config.readonly = true;
    });
  }
};
