import { get } from "lodash";
export default {
  code: "changeDictCode",
  name: "修改字典",
  desc: "修改字典",
  func: function(path, dictCode) {
    const config = get(this.pageLayoutMap, path, null);
    if (!config) {
      return false;
    }
    config.dictCode = dictCode; // 证件类型
  }
};
