import { request } from "@/api/service";
import store from "@/store";
export default {
  code: "contractToClaim",
  name: "合同转换履约信息",
  desc: "合同转换履约信息",
  func: async function() {
    const { id, contractId, coverageId } = this.pageContext.$route.query;
    this.pageForm.id = id;
    if (contractId) {
      this.usableBenefits(contractId);
    }
    if (id || !contractId) {
      return false;
    }
    this.pageForm.contractId = contractId;
    this.pageForm.coverageId = coverageId;
    const res = await request({
      url: `/afis-carservice/contract/info/${contractId}`,
      method: "get"
    });
    const { customer, vehicle, coverages } = res;
    if (customer) {
      if (customer.certificateType !== "6") {
        this.pageForm.walk.receiverName = customer.customerName;
        this.pageForm.walk.receiverNo = customer.certificateNo;
        if (customer.certificateType !== "3") {
          this.pageForm.walk.receiverCertType = customer.certificateType;
        }
      }
      this.pageForm.walk.receiverMobile = customer.phone;
    }
    if (vehicle) {
      this.pageForm.vehicle.plateNo = vehicle.plateNo;
    }
    if (store.getters.info.orgId) {
      const resOrg = await request({
        url: "/organization/dealer/info/load",
        method: "post",
        data: {
          id: store.getters.info.orgId
        }
      });
      this.pageForm.walk.receiverCode = [resOrg.provinceCode, resOrg.cityCode, resOrg.regionCode];
      this.pageForm.walk.province = resOrg.province;
      this.pageForm.walk.provinceCode = resOrg.provinceCode;
      this.pageForm.walk.city = resOrg.city;
      this.pageForm.walk.cityCode = resOrg.cityCode;
      this.pageForm.walk.region = resOrg.region;
      this.pageForm.walk.regionCode = resOrg.regionCode;
      this.pageForm.walk.receiverAddressDesc = `${resOrg.province}/${resOrg.city}/${resOrg.region} ${resOrg.address}`;
      this.pageForm.walk.receiverAddress = `${resOrg.address}`;
      this.pageForm.walk.map = `${resOrg.address}`;
      this.pageForm.walk.latitude = resOrg.latitude;
      this.pageForm.walk.longitude = resOrg.longitude;
      // 代步车型赋值
      this.pageForm.walk.carWalkType = coverages[0].carWalkType;
      this.pageForm.walk.carWalkTypeName = coverages[0].carWalkTypeName;

    }
  }
};
