import dayjs from "dayjs";
import { get } from "lodash";
export default {
  code: "dateMax",
  name: "设置日期最大值",
  desc: "设置日期最大值",
  func: function(path, opts, defaultVal) {
    const config = get(this.pageLayoutMap, path, null);
    if (!config) {
      return false;
    }
    if (!this.val) {
      config.maxDate = defaultVal;
      return false;
    }
    let dayOpt = dayjs(this.val);
    opts &&
      opts.forEach(item => {
        dayOpt = dayOpt[item.opt](item.value, item.unit);
      });
    config.maxDate = dayOpt.toDate();
  }
};
