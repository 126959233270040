export default {
  code: "calcApplyAmount",
  name: "总维修金额",
  desc: "总维修金额",
  func: function() {
    // if (
    //   !(
    //     this.form.partsAmount &&
    //     this.form.assistAmount &&
    //     this.form.workingHourAmount
    //   )
    // ) {
    //   return false;
    // }
    const applyAmount = Number(this.form.partsAmount) + Number(this.form.assistAmount) + Number(this.form.workingHourAmount);
    this.form.actualAmount = applyAmount;
    this.form.applyAmount = applyAmount;
  }
};
