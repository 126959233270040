import { get } from "lodash";
export default {
  code: "onRequired",
  name: "打开必填项",
  desc: "打开必填项",
  func: function(paths) {
    paths.forEach(path => {
      const config = get(this.pageLayoutMap, path, {});
      if (!config.rules) {
        return false;
      }
      config.rules.forEach(item => {
        if (item.required) {
          item.disabled = false;
        }
      });
    });
  }
};
