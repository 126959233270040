import { keys, cloneDeep, merge } from "lodash";
export default {
  code: "pageToService",
  name: "页面数据转换服务数据",
  desc: "页面数据转换服务数据",
  func: function() {
    const resFormData = {
      claimType: "M" /** 赔付类型，M-钱/V-车 */
    };
    const cloneform = cloneDeep(this.pageForm);
    if (cloneform.id) {
      resFormData.id = cloneform.id;
      resFormData.contractId = cloneform.contractId;
      resFormData.coverageId = cloneform.coverageId;
      resFormData.benefitId = cloneform.benefitId;
    } else {
      resFormData.contractId = cloneform.contractId;
      resFormData.coverageId = cloneform.coverageId;
    }
    // 客户信息
    if (cloneform.customer) {
      resFormData.reportName = cloneform.customer.reportName;
      resFormData.reportPhone = cloneform.customer.reportPhone;
    }
    // 车辆信息
    if (cloneform.vehicle) {
      resFormData.plateNo = cloneform.vehicle.plateNo;
    }
    // 账户信息
    if (cloneform.account) {
      resFormData.bankCode = cloneform.account.bankCode;
      resFormData.accountName = cloneform.account.accountName;
      resFormData.bankName = cloneform.account.bankName;
    }
    // 延保申请信息
    if (cloneform.extended) {
      resFormData.extended = {};
      merge(resFormData.extended, cloneform.extended);
      resFormData.applyName = cloneform.extended.applyName;
      resFormData.applyPhone = cloneform.extended.applyPhone;
      // 故障信息
      if (cloneform.fault) {
        merge(resFormData.extended, cloneform.fault);
      }
    }
    // 代步车申请信息
    if (cloneform.walk) {
      resFormData.walk = {};
      merge(resFormData.walk, cloneform.walk);
      resFormData.applyName = cloneform.walk.applyName;
      resFormData.applyPhone = cloneform.walk.applyPhone;
      // 故障信息
      if (cloneform.fault) {
        merge(resFormData.walk, cloneform.fault);
        resFormData.reportNo = cloneform.fault.reportNo;
        resFormData.accidentDate = cloneform.fault.accidentDate;
        resFormData.reportTime = cloneform.fault.reportTime;
        resFormData.accidentCourse = cloneform.fault.accidentCourse;
        // 省市县
        resFormData.address = cloneform.fault.address;
        resFormData.provinceCode = cloneform.fault.provinceCode;
        resFormData.provinceName = cloneform.fault.provinceName;
        resFormData.cityCode = cloneform.fault.cityCode;
        resFormData.cityName = cloneform.fault.cityName;
        resFormData.countyCode = cloneform.fault.countyCode;
        resFormData.countyName = cloneform.fault.countyName;
      }
    }
    // 图片转换
    resFormData.fileRecords = [];
    cloneform.fileRecords &&
      keys(cloneform.fileRecords).forEach(imageKey => {
        cloneform.fileRecords[imageKey] &&
          cloneform.fileRecords[imageKey].length &&
          cloneform.fileRecords[imageKey].forEach(image => {
            resFormData.fileRecords.push(image);
          });
      });
    return resFormData;
  }
};
