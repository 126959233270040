export default {
  code: "certificateNo",
  name: "身份证校验",
  desc: "身份证校验",
  func: function(val, validator) {
    if (this.form.certificateType === "1" && !/(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/.test(val)) {
      return false;
    }
    return true;
  }
};
