import dayjs from "dayjs";
import { get } from "lodash";
/**
 * opts:[{opt:'add&subtract',value:1,unit:'day&month&year'}]
 */
export default {
  code: "dateMin",
  name: "设置日期最小值",
  desc: "设置日期最小值",
  func: function(path, opts, defaultVal) {
    const config = get(this.pageLayoutMap, path, null);
    if (!config) {
      return false;
    }
    if (!this.val) {
      config.minDate = defaultVal;
      return false;
    }
    let dayOpt = dayjs(this.val);
    opts &&
      opts.forEach(item => {
        dayOpt = dayOpt[item.opt](item.value, item.unit);
      });
    config.minDate = dayOpt.toDate();
  }
};
