import { request } from "@/api/service";
export default {
  code: "usableBenefits",
  name: "查询权益剩余保额及次数",
  desc: "查询权益剩余保额及次数",
  func: async function(contractId) {
    if (!contractId) {
      return false;
    }
    const res = await request({
      url: "/afis-carservice/claim/usableBenefits",
      method: "post",
      data: {
        contractId: contractId
      }
    });
    const { amount, frequency } = res;
    if (this.pageForm.extended && amount) {
      this.pageForm.extended.balanceAmount = amount;
    }
    if (this.pageForm.walk && frequency) {
      this.pageForm.walk.balanceCount = frequency;
    }
  }
};
