export default {
  code: "customerTypeChange",
  name: "客户类型修改",
  desc: "客户类型修改",
  func: function() {
    if (!this.val) {
      return false;
    }
    if (this.val === "1") {
      // 个人
      this.onShow(["customer.birthday", "customer.gender"]);
      this.changeDictCode("customer.certificateType", "CERTIFICATE_TYPE_PER");
    } else if (this.val === "2") {
      // 公司
      this.offShow(["customer.birthday", "customer.gender"]);
      this.changeDictCode("customer.certificateType", "CERTIFICATE_TYPE_PUB");
    }
  }
};
