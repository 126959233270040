import { get } from "lodash";
export default {
  code: "onShow",
  name: "显示",
  desc: "显示",
  func: function(paths) {
    paths.forEach(path => {
      const config = get(this.pageLayoutMap, path, null);
      if (!config) {
        return false;
      }
      config.show = true;
    });
  }
};
