export default {
  code: "email",
  name: "电子邮箱",
  desc: "电子邮箱",
  func: function(val, validator) {
    if (val && !/^([\\.?a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(val)) {
      return false;
    }
    return true;
  }
};
