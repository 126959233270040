import { get, isArray } from "lodash";
import { request } from "@/api/service";
export default {
  code: "remoteGetOption",
  name: "Get远程获取下拉并设置",
  desc: "Get远程获取下拉并设置",
  func: function(path) {
    const config = get(this.pageLayoutMap, path, null);
    if (!config) {
      return false;
    }
    if (!config.api) {
      return false;
    }
    const data = [];
    for (const item of config.api.relateFields) {
      let val;
      if (item.type === "path") {
        val = get(this.pageForm, item.value, null);
      } else if (item.type === "static") {
        val = item.value;
      } else {
        val = item.value;
      }
      if (item.required && !val) {
        return false;
      }
      data.push = `${item.code}=${val}`;
    }
    request({
      url: `${config.api.url}?${data.join("&")}`,
      method: config.api.method || "GET",
      data: {}
    }).then(res => {
      if (isArray(res)) {
        config.options = res || [];
      }
    });
  }
};
