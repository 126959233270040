export default {
  code: "setRulesByHoldCommercial",
  name: "图片控制",
  desc: "图片控制",
  func: function() {
    if (!(this.pageLayoutMap && this.pageLayoutMap.fileRecords)) {
      return false;
    }
    if (this.val === "1") {
      // eslint-disable-next-line guard-for-in
      for (const code in this.pageLayoutMap.fileRecords) {
        const item = this.pageLayoutMap.fileRecords[code];
        item.required = false;
      }
    } else {
      for (const code in this.pageLayoutMap.fileRecords) {
        if (code === "cscClaim8") {
          continue;
        }
        const item = this.pageLayoutMap.fileRecords[code];
        item.required = true;
      }
    }
  }
};
