import { request } from "@/api/service";
export default {
  code: "getContractInfo",
  name: "获取合同信息",
  desc: "获取合同信息",
  func: async function(contractId) {
    if (!contractId) {
      return false;
    }
    const res = await request({
      url: `/afis-carservice/contract/info/${contractId}`,
      method: "get"
    });
    const { customer, vehicle, coverages } = res;
    this.pageForm.extend = {
      customer: customer,
      vehicle: vehicle,
      coverage: coverages && coverages[0]
    };
    return { customer, vehicle };
  }
};
