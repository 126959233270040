import { isString } from "lodash";
export default {
  code: "startDate",
  name: "合同生效日期校验",
  desc: "合同生效日期校验",
  func: function (val, validator) {
    const form = this.pageForm;
    const { biStartDate, biEndDate } = form.vehicle;
    if (biEndDate >= new Date(isString(val) ? val + ' 00:00:00' : val).getTime() && new Date(isString(val) ? val + ' 00:00:00' : val).getTime() >= biStartDate) {
      return true;
    }
    return false;
  }
};
