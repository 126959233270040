import { get, set } from "lodash";
export default {
  code: "parseFloat",
  name: "转换",
  desc: "转换",
  func: function(path, digit) {
    const val = String(get(this.pageForm, path));
    if (!val) {
      return null;
    }
    if (val.indexOf(".") > -1 && val.length - val.indexOf(".") > digit + 1) {
      const value = parseFloat(val).toFixed(digit);
      this.context.$nextTick(() => {
        set(this.pageForm, path, value);
      });
    }
  }
};
