import { assign } from "lodash";
import { request } from "@/api/service";
export default {
  code: "serviceToPage",
  name: "服务数据转换页面信息",
  desc: "服务数据转换页面信息",
  func: async function(id) {
    const { contractId } = this.pageContext.$route.query;
    this.contractToClaim();
    if (contractId) {
      this.getContractInfo(contractId);
    }
    if (!id) {
      return null;
    }
    const res = await request({
      url: "/afis-carservice/web/claim/info",
      method: "post",
      data: { id: id }
    });
    const { walk, fileRecords } = res;

    if (id) {
      this.pageForm.id = id;
      this.pageForm.contractId = res.contractId;
      this.pageForm.coverageId = res.coverageId;
      this.pageForm.benefitId = res.benefitId;
      if (res.claimStatus === "3" && this.pageContext.pageData) {
        // 状态为退回修改，不能暂存操作
        this.pageContext.pageData.storageFlag = false;
      }
      this.getContractInfo(res.contractId);
    }
    // 代步车服务补偿信息
    if (walk) {
      assign(this.pageForm.walk, walk);
      // 其它转换
      this.pageForm.walk.applyName = res.applyName;
      this.pageForm.walk.applyPhone = res.applyPhone;
      this.pageForm.walk.receiverAddressDesc = walk.province ? `${walk.province}/${walk.city}/${walk.region} ${walk.receiverAddress || ""}` : (walk.receiverAddress || "");
      // 故障信息
      this.pageForm.fault.reportNo = res.reportNo;
      this.pageForm.fault.accidentDate = res.accidentDate;
      this.pageForm.fault.reportTime = res.reportTime;
      this.pageForm.fault.accidentCourse = res.accidentCourse;
      this.pageForm.fault.address = res.address;
      // 省市县
      if (res.provinceCode) {
        this.pageForm.fault.areaCode = [res.provinceCode, res.cityCode, res.countyCode];
      }
      if (walk.provinceCode) {
        this.pageForm.walk.areaCode = [walk.provinceCode, walk.cityCode, walk.regionCode];
      }
      this.pageForm.walk.receiverCode = [walk.provinceCode, walk.cityCode, walk.regionCode];
      this.pageForm.walk.returnAddressDesc = walk.returnProvince ? `${walk.returnProvince}/${walk.returnCity}/${walk.returnRegion} ${walk.returnAddress || ""}` : walk.returnAddress || "";
      this.pageForm.walk.map = walk.receiverAddress;
      this.pageForm.walk.returnCode = [walk.returnProvinceCode, walk.returnCityCode, walk.returnRegionCode];
      this.pageForm.walk.returnMap = walk.returnAddress;
    }
    // 影像
    if (fileRecords && fileRecords.length) {
      const isImg = function(fileItem) {
        if (!fileItem.fileName) {
          return false;
        }
        const suffix = fileItem.fileName.substring(fileItem.fileName.lastIndexOf(".") + 1);
        if (".jpg.png.jpeg.jif.jfif".indexOf(suffix) !== -1) {
          return true;
        }
        return false;
      };
      const imageObj = {};
      fileRecords.forEach(image => {
        image.isImage = isImg(image);
        image.url = image.filePath;
        if (imageObj[image.fileCategory]) {
          imageObj[image.fileCategory].push(image);
        } else {
          imageObj[image.fileCategory] = [image];
        }
      });
      this.pageForm.fileRecords = imageObj;
    }
    // 车辆
    this.pageForm.vehicle.plateNo = res.plateNo;
  }
};
