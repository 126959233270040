import dayjs from "dayjs";
import { set } from "lodash";
import { Toast } from "vant";
import { request } from "@/api/service";

export default {
  label: "校验出险时间",
  code: "validAccidentDate",
  desc: "校验出险时间",
  func: async function () {
    // 获取出险时间
    const { accidentDate } = this.pageForm.fault;
    if (!accidentDate) return;
    // const res = await request({
    //   url: `/afis-carservice/contract/info/${this.pageForm.contractId}`,
    //   method: "get"
    // });
    // if (
    //   res.coverages[0].startDate >= new Date(dayjs(accidentDate).subtract(5, "days").format('YYYY-MM-DD 23:59:59')).getTime()
    // ) {
    //   Toast("等待期出险，无法申请履约");
    //   set(this.pageForm.fault, "accidentDate", null);
    // }
  }
};
