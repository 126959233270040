import dayjs from "dayjs";
export const setTimeOneOrHalf = (time = Date.now(), callback) => {
  let addDate = dayjs(
    dayjs(time)
      .add(1, "hour")
      .toDate()
  ).toDate();
  let halfDate = dayjs(
    dayjs(time)
      .add(1, "hour")
      .format("YYYY-MM-DD HH:30")
  ).toDate();
  let oneDate = dayjs(
    dayjs(time)
      .add(2, "hour")
      .format("YYYY-MM-DD HH:00")
  ).toDate();
  let addNumDate = addDate.getTime();
  let addHalfDate = halfDate.getTime();
  let flag = addNumDate < addHalfDate;
  callback && callback(flag);
  return flag ? halfDate : oneDate;
};
